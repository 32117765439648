.workflow.wrapper {
  text-align: justify;
  text-justify: inter-word;
}

.workflow__title {
  margin: 240px 0 25px;
  width: fit-content;
  font: var(--font-h-l);
  color: var(--color-black);
}

@media screen and (max-width: 600px) {
  .workflow__title {
    margin: 120px 0 15px;
    font-size: 1.65rem;
  }
}

.workflow__container .collapsing__title {
  white-space: preserve;
}

.workflow__container .collapsing__img {
  padding: 5px;
  border-radius: 50%;
  background-color: var(--color-white);
  transition: background-color 0.15s ease-out;
}

.workflow__container .collapsing__container:hover .collapsing__img {
  padding: 5px;
  border-radius: 50%;
  background-color: var(--color-accent);
}

.workflow__container .collapsing__container {
  border-top: none;
}

.workflow__container .collapsing__bottomLine:last-of-type {
  display: none;
}

.workflow__itemContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-l-grey);
  padding: 20px 0;
  font: var(--font-ml);
}

.workflow__itemContainer:last-of-type {
  border-bottom: none;
}

@media screen and (max-width: 600px) {
  .workflow__itemContainer {
    justify-content: flex-start;
    padding: 13px 0;
    font: var(--font-xs);
  }
}

.workflow__itemText {
  box-sizing: border-box;
  padding: 2px 0 2px 30px;
  width: 60%;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  .workflow__itemText {
    margin: 0;
    padding: 2px 0 2px 30px;
    width: 100%;
    font: var(--font-h-s);
    text-transform: capitalize;
  }
}

.prices.page__wrapper .workflow.wrapper {
  margin: 0 auto 220px;
}

@media screen and (max-width: 750px) {
  .prices.page__wrapper .workflow.wrapper {
    margin: 0 auto 80px;
  }
}

.workflow__container h3 {
  margin: 25px 0 10px;
  padding: 0 100px 0 0;
  color: var(--color-black);
  font: var(--font-m);
  line-height: 1.3;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  .workflow__container h3 {
    display: inline-block;
    margin: 40px 0 0;
    padding: 0;
    font-size: 1.1rem;
    text-transform: lowercase;
  }
  .workflow__container h3:first-letter {
    text-transform: uppercase;
  }
}

.workflow__container p {
  margin: 16px 0 0;
  padding: 0 100px 30px 0;
  color: var(--color-dark-grey);
  font: var(--font-ml);
  line-height: 1.6;
}

@media screen and (max-width: 600px) {
  .workflow__container p {
    padding: 0;
  }
}

.workflow__container strong {
  font-weight: 500;
}

.workflow__container ul,
.workflow__container ol {
  margin: 0;
  padding: 0 100px 0 0;
}

@media screen and (max-width: 600px) {
  .workflow__container ul,
  .workflow__container ol {
    padding: 0;
  }
}

.workflow__container ul {
  padding-left: 1rem;
}

.workflow__container ol {
  padding-left: 1.56rem;
}

.workflow__container li {
  margin: 9px 0 0;
}

@media screen and (max-width: 600px) {
  .workflow__container li {
    margin: 7px 0 0;
  }
}

.workflow__container li::marker {
  color: var(--color-accent);
}

.workflow__container ol li::marker {
  content: counter(list-item) ".  ";
}

.workflow__container blockquote {
  margin: 20px 0 25px;
  border-left: solid 2px var(--color-accent);
  padding: 0 0 0 2rem;
  color: var(--color-black);
  font-size: 1.625rem;
  line-height: 1.4;
}

.workflow__container blockquote p {
  padding: 0;
}

@media screen and (max-width: 1200px) {
  .workflow__container blockquote {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1000px) {
  .workflow__container blockquote {
    margin: 20px 0 10px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 800px) {
  .workflow__container blockquote {
    font-size: 1.325rem;
  }
}

@media screen and (max-width: 600px) {
  .workflow__container blockquote {
    margin: 30px 0;
    border: none;
    padding: 0;
    font-size: 1.25rem;
  }
}

.workflow__container a {
  transition: color 0.25s ease-out;
  color: var(--color-accent);
  text-decoration: underline;
}
.workflow__container a:hover {
  color: var(--color-visited);
}

/* About page styles */

.about .workflow.wrapper {
  margin: 0 auto 120px;
}

@media screen and (max-width: 600px) {
  .about .workflow.wrapper {
    margin: 0 auto 80px;
  }
}

/* Main page styles */

.mainPage .workflow__title {
  color: var(--color-white);
}

.mainPage .collapsing__title {
  width: 100%;
  font: var(--font-h-s);
  color: var(--color-m-grey);
}
.mainPage .workflow__container p {
  color: var(--color-grey);
}
.mainPage .collapsing__bottomLine {
  color: var(--color-m-grey);
}

/* Блок, куда складываются скрытые пункты (с 7-го и далее). */
.workflow__hidden {
  overflow: hidden; /* важно, чтобы при max-height:0 содержимое скрывалось */
  transition: max-height 0.5s ease;
  max-height: 0; /* начальное состояние (скрыто) */
}

.workflow__show-all {
  cursor: pointer;
  text-align: center;
  margin-top: 16px; /* чтобы не прилипало вплотную */
}

.workflow__divider {
  margin: 0 auto;
  width: 50%;
  border: none;
  border-top: 1px solid #ccc;
}

.workflow__arrow-down {
  margin-top: 8px;
  font-size: 18px;
  color: var(--color-accent);
  cursor: pointer;
  transition: color 0.25s ease-out;
}

.workflow__arrow-down:hover {
  color: var(--color-visited);
}
.workflow__container .workflow__hidden .collapsing__container {
  border-top: 1px solid var(--color-l-grey);
}

.workflow__container .collapsing__img {
  margin-left: 10px;
}

@media screen and (min-width: 600px) {
  .mainPage .workflow__container p:last-of-type,
  .faq__page .workflow__container p:last-of-type,
  .about .workflow__container p:last-of-type {
    margin: 0;
    padding: 0;
  }
}


.faq__page .workflow__container p {
  color: var(--color-grey);
}